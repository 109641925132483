import { ValidatorFn } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { Dayjs } from 'dayjs';
import { TableDataSource } from 'shared/table/table-data-source';

import { AssetButtonAction } from './Asset';
import { AuthorizationActions, DataResultAuthorizationAttributes } from './Authorization';
import { BillingButtonAction, TransferButtonAction } from './Billing';
import { BlacklistButtonAction } from './Blacklist';
import { CarButtonAction } from './Car';
import { ChargingStationButtonAction, PlanningButtonAction } from './ChargingStation';
import { ChargingStationCertificatesButtonAction } from './ChargingStationCertificate';
import { ChargingStationTemplateButtonAction } from './ChargingStationTemplate';
import { CompanyButtonAction } from './Company';
import { ButtonAction, ButtonActionColor, KeyValue } from './GlobalType';
import { LogButtonAction } from './Log';
import { OCPIButtonAction } from './ocpi/OCPIEndpoint';
import { OICPButtonAction } from './oicp/OICPEndpoint';
import { PlugAndChargeButtonAction } from './PlugAndCharge';
import { PricingButtonAction } from './Pricing';
import { RegistrationTokenButtonAction } from './RegistrationToken';
import { SiteButtonAction } from './Site';
import { SiteAreaButtonAction } from './SiteArea';
import { SubscriptionButtonAction } from './Subscription';
import { TagButtonAction } from './Tag';
import { TenantButtonAction } from './Tenant';
import { TransactionButtonAction } from './Transaction';
import { UserButtonAction } from './User';
import { UserGroupButtonAction } from './UserGroup';
import { UserSubscriptionButtonAction } from './UserSubscription';

export interface TableData extends DataResultAuthorizationAttributes, AuthorizationActions {
  id: string | number;
  key?: string;
  isSelected?: boolean;
  isSelectable?: boolean;
  isExpanded?: boolean;
}

export enum TableDataSourceMode {
  READ_WRITE = 'RW',
  READ_ONLY = 'RO',
}

export interface TableFilterDef {
  id: TableFilter;
  httpID: string;
  type: FilterType;
  name: string;
  label?: string;
  currentValue?: any;
  defaultValue?: any;
  class?: string;
  items?: KeyValue[];
  dialogComponent?: any;
  dialogComponentData?: any;
  reset?: () => void;
  multiple?: boolean;
  exhaustive?: boolean;
  cleared?: boolean;
  dateRangeTableFilterDef?: DateTimeTableFilterDef;
  dependentFilters?: TableFilterDef[];
  visible?: boolean;
  tooltip?: string;
}

export enum TableFilter {
  CHARGING_STATION = 'charging_station',
  CHARGING_STATION_OFFLINE = 'charging_station_offline',
  CAR_CATALOG = 'car_catalog',
  CAR_MAKER = 'car_maker',
  CAR = 'car',
  COMPANY = 'company',
  CONNECTOR = 'connector',
  CURRENT_TYPE = 'current_type',
  DATE_TIME_RANGE = 'date_time_range',
  DATE_TIME = 'date_time',
  ERROR_TYPE = 'error_type',
  ISSUER = 'issuer',
  REFUND_REPORT = 'refund_report',
  SITE_AREA = 'site_area',
  SITE = 'site',
  TAG_STATUS = 'tag_status',
  INVOICE_STATUS = 'invoice_status',
  TRANSFER_STATUS = 'transfer_status',
  TAG = 'tag',
  USER = 'user',
  USER_ROLE = 'user_role',
  USER_STATUS = 'user_status',
  USER_FREE_ACCESS = 'user_free_access',
  USER_TECHNICAL = 'user_technical',
  USER_GROUP = 'user_group',
  USER_WITH_NO_USER_GROUP = 'user_with_no_user_group',
  LOG_ACTION = 'log_action',
  LOG_LEVEL = 'log_level',
  LOG_SOURCE = 'log_source',
  TRANSACTION_INACTIVITY_STATUS = 'transaction_inactivity_status',
  TRANSACTION_REFUND_STATUS = 'transaction_refund_status',
}

export interface DateTimeTableFilterDef {
  startDate?: Dayjs;
  endDate?: Dayjs;
  startDateTimeHttpID?: string;
  endDateTimeHttpID?: string;
  singleDatePicker?: boolean;
  timePicker?: boolean;
  timePickerSeconds?: boolean;
  allowClearDate?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export interface DateTimeRange {
  startDate: Date;
  endDate: Date;
}

export interface DropdownItem {
  id: string;
  name: string;
  icon?: string;
  class?: string;
  disabled?: boolean;
  tooltip: string;
}

export enum ActionType {
  BUTTON = 'button',
  SLIDE = 'slide'
}

export enum FilterType {
  ALL_KEY = 'all',
  DROPDOWN = 'dropdown',
  DIALOG_TABLE = 'dialog-table',
  DATE = 'date',
  DATE_RANGE = 'date-range',
  SLIDE = 'slide'
}

export interface TableActionDef {
  id: ButtonAction | CompanyButtonAction | TenantButtonAction | SiteAreaButtonAction | ChargingStationButtonAction | PlanningButtonAction |
  UserButtonAction | TransactionButtonAction | SiteButtonAction | OCPIButtonAction | OICPButtonAction | AssetButtonAction |
  BillingButtonAction | CarButtonAction | LogButtonAction | RegistrationTokenButtonAction | TagButtonAction |
  SubscriptionButtonAction | UserSubscriptionButtonAction | PricingButtonAction | ChargingStationTemplateButtonAction |
  TransferButtonAction | UserGroupButtonAction | PlugAndChargeButtonAction | ChargingStationCertificatesButtonAction | BlacklistButtonAction;
  type: ActionType;
  currentValue?: any;
  name: string;
  icon?: string;
  color?: ButtonActionColor;
  disabled?: boolean;
  visible?: boolean;
  isDropdownMenu?: boolean;
  dropdownActions?: TableActionDef[];
  tooltip: string;
  formRowAction?: boolean;
  linkedToListSelection?: boolean;
  action?(...args: any[]): void;
}

export interface TableDef {
  id?: string;
  class?: string;
  isEditable?: boolean;
  errorMessage?: string;
  rowSelection?: {
    enabled: boolean;
    multiple?: boolean;
  };
  footer?: {
    enabled: boolean;
  };
  search?: {
    enabled: boolean;
  };
  design?: {
    flat: boolean;
  };
  rowDetails?: {
    enabled: boolean;
    detailsField?: string;
    angularComponent?: any;
    parentDataSource?: TableDataSource<any>;
    showDetailsField?: string;
    additionalParameters?: any;
  };
  rowFieldNameIdentifier?: string;
  isSimpleTable?: boolean;
  hasDynamicRowAction?: boolean;
}

export interface TableColumnDef {
  id: string;
  name?: string;
  footerName?: string;
  type?: string;
  editType?: TableEditType;
  unique?: boolean;
  canBeDisabled?: boolean;
  validators?: ValidatorFn[];
  errors?: {
    id: string;
    message: string;
    messageParams?: Record<string, unknown>;
  }[];
  headerClass?: string;
  class?: string;
  formatter?: (value: any, row?: any) => string | null;
  sortable?: boolean;
  sorted?: boolean;
  direction?: SortDirection;
  isAngularComponent?: boolean;
  angularComponent?: any;
  defaultValue?: any;
  additionalParameters?: any;
  visible?: boolean;
  disabled?: boolean;
  values?: string[];
}

export interface TableSearch {
  search: string;
}

export enum TableEditType {
  RADIO_BUTTON = 'radiobutton',
  DROP_DOWN = 'dropdown',
  CHECK_BOX = 'checkbox',
  INPUT = 'input',
  DATE_TIME_PICKER = 'datetimepicker',
  DISPLAY_ONLY = 'displayonly',
}
