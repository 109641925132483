import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PRICING_ENTITY_TYPES } from 'shared/model/pricing-entity-types.model';

import { PricingEntity } from '../../types/Pricing';

@Pipe({name: 'appPricingEntityType'})
export class AppPricingEntityTypePipe implements PipeTransform {

  // eslint-disable-next-line no-useless-constructor
  public constructor(private translateService: TranslateService) {};

  public transform(currentPricingEntityType: PricingEntity): string {
    for (const entityType of PRICING_ENTITY_TYPES) {
      if (entityType.key === currentPricingEntityType) {
        return this.translateService.instant(entityType.value);
      }
    }
    return currentPricingEntityType;
  }
}
