import { PricingEntity } from 'types/Pricing';

import { KeyValue } from '../../types/GlobalType';

export const PRICING_ENTITY_TYPES: KeyValue[] = [
  { key: PricingEntity.CHARGING_STATION, value: 'settings.pricing.pricing_entities.charging_station' },
  { key: PricingEntity.COMPANY, value: 'settings.pricing.pricing_entities.company' },
  { key: PricingEntity.SITE, value: 'settings.pricing.pricing_entities.site' },
  { key: PricingEntity.SITE_AREA, value: 'settings.pricing.pricing_entities.site_area' },
  { key: PricingEntity.TENANT, value: 'settings.pricing.pricing_entities.tenant' },
  { key: PricingEntity.USER, value: 'settings.pricing.pricing_entities.user' },
  { key: PricingEntity.USER_GROUP, value: 'settings.pricing.pricing_entities.user_group' },
];
